import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getDoctorDetails, resetUserAccount, suspendDentistAccount } from '../../../Redux/Actions/admin/adminPanel'

const ConfirmationModal = ({ isModalOpen, handleConfirmClose, data, selectedType }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleUserSuspend = async () => {
        try {
            const value = {
                userId: data
            }
            const res = await dispatch(suspendDentistAccount(value))

            if (res?.payload?.success) {
                handleConfirmClose()
                toast.success(res?.payload?.data);
                dispatch(getDoctorDetails(data))
            } else {
                toast.error(res?.payload?.data)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const handleUserActive = async () => {
        try {
            const value = {
                userId: data
            }
            const res = await dispatch(suspendDentistAccount(value))
            if (res?.payload?.success) {
                handleConfirmClose()
                toast.success(res?.payload?.data);
                dispatch(getDoctorDetails(data))
            } else {
                toast.error(res?.payload?.data)
            }
        } catch (error) {
            console.log("error", error)
        }
    }


    const handleUserReset = async () => {
        try {
            const value = {
                userId: data
            }
            const res = await dispatch(resetUserAccount(value))
            console.log(res, "dffdf2323232")
            if (res?.payload?.success) {
                handleConfirmClose()
                toast.success(res?.payload?.data);
                dispatch(getDoctorDetails(data))
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }


    return (
        <Modal
            show={isModalOpen}
            onHide={handleConfirmClose}
            className="comn-modal-set"
        >
            {selectedType === "userSuspend" &&
                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Suspend Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text are-you-sure-txt">

                            Are you sure you want to suspend?
                        </p>
                    </Modal.Body>
                </>
            }

            {selectedType === "userActive" &&
                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Active Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to active this account?
                        </p>
                    </Modal.Body>
                </>

            }

            {selectedType === "userReset" &&
                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Reset Password

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to Reset ?
                        </p>
                    </Modal.Body>
                </>

            }

            <Modal.Footer className="border-none justify-content-center modal-footer">
                {selectedType === "userSuspend" &&
                    <button
                        onClick={() => handleUserSuspend()}

                        className="m-0  submit-btn-popup pop-cancel-btn "
                    >
                        Yes
                    </button>
                }

                {selectedType === "userActive" &&
                    <button
                        onClick={() => handleUserActive()}

                        className="m-0  submit-btn-popup pop-cancel-btn "
                    >
                        Yes
                    </button>
                }

                {selectedType === "userReset" &&
                    <button
                        onClick={() => handleUserReset()}
                        className="m-0  submit-btn-popup pop-cancel-btn "
                    >
                        Yes
                    </button>
                }

                <button
                    onClick={handleConfirmClose}
                    className="modal-f-btn pop-cancel-btn cancel-btn-popup"
                >
                    No
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationModal
