import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import admin_avatar_header from "../../../assets/images/avatar.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import DentitstModal from "./DentitstModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDentist,
  getDentist,
} from "../../../Redux/Actions/admin/adminPanel";
import moment from "moment";

const DentistAll = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const dentists = useSelector((state) => state.adminPanel.dentists);
  const [show, setShow] = useState(false);
  const [dentistDetail, setDentistDetail] = useState();
  const [filter, setFilter] = useState({
    search: undefined,
    date: undefined,
  });

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    if (data == "create") {
      setDentistDetail();
    } else {
      setDentistDetail(data);
    }
  };

  useEffect(() => {
    dispatch(getDentist({ filter: filter }));
  }, []);

  const handleFilter = (e) => {
    let { value, name } = e.target;
    setFilter({ ...filter, [name]: value });
    dispatch(getDentist({ filter: { ...filter, [name]: value } }));
  };

  return (
    <>
      <DentitstModal
        show={show}
        handleClose={handleClose}
        dentistDetail={dentistDetail}
      />
      <Layout>
        <div id="layout-wrapper" className="bg-dash admin-table">
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <div>
                        <span className="small_text">Dentists</span>
                        <h4 className="mb-sm-0 font-size-28">
                          Dentists
                          <span className="header-title-text">
                            {dentists?.length}
                          </span>
                        </h4>
                      </div>
                      {/* <div className="page-title-right">
                        <form className="app-search d-none d-lg-block ">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="position-relative w-100">
                              <input type="text" className="form-control" placeholder="Search" />
                              <span className="bx bx-search"></span>
                            </div>
                            <img
                              src={admin_avatar_header}
                              className="avata_serch"
                              alt=""
                            />
                          </div>
                        </form>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                    <input
                      name="search"
                      type="text"
                      class="form-control filter-input-element"
                      id=""
                      placeholder="Search Here"
                      onChange={(e) => handleFilter(e)}
                    />
                  </div>
                  {/* <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                    <div class="form-group">
                      <select class="form-control filter-select-element">
                        <option>Status</option>
                        <option>2</option>
                      </select>
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="members_tbl">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                            <h4 className="title_text">Dentists</h4>
                            <span>
                              <Link>
                                <button
                                  type="button"
                                  className="btn cre_new"
                                  onClick={() => {
                                    handleShow("create");
                                  }}
                                >
                                  Create New
                                </button>
                              </Link>
                            </span>
                          </div>
                          <div className="table-responsive table-defaut-design dealer-table-c">
                            <table
                              id="datatable"
                              className="table  vehicles_table  w-100"
                            >
                              <thead>
                                <tr>
                                  <th>Created</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Consultations Complete</th>
                                  <th>Consultations Upcoming</th>
                                  <th>Last Login</th>
                                  <th>Actions</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody className="td_color trans_table_fix_height">
                                {dentists?.map((item, i) => {
                                  if(item?.role === "dentist")
                                  return (
                                    <>
                                      <tr>
                                        <td>
                                          {" "}
                                          {moment(item?.createdAt).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                        <td  
                                        style={{cursor: "pointer"}}
                                        onClick={() => navigate(`/admin/memberdentistdetails/${item?._id}`)}>
                                          {item?.firstName
                                            ? item?.firstName
                                                .charAt(0)
                                                .toUpperCase() +
                                              item?.firstName.slice(1)
                                            : ""}{" "}
                                          {item?.lastName
                                            ? item?.lastName
                                                .charAt(0)
                                                .toUpperCase() +
                                              item?.lastName.slice(1)
                                            : ""}
                                        </td>

                                        <td>{item?.email}</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>
                                          {moment(item?.lastLogin).format(
                                            "DD/MM/YYYY hh:mm"
                                          )}
                                        </td>
                                        <td
                                          className="action_btn editBtn"
                                          valign="middle"
                                        >
                                          <Link to="#">
                                            <button
                                              onClick={() => handleShow(item)}
                                              className="border_btn"
                                            >
                                              Edit
                                            </button>
                                          </Link>
                                        </td>
                                        <td
                                          valign="middle"
                                          className="delete_button"
                                        >
                                          <button
                                            onClick={() =>
                                              dispatch(deleteDentist(item?._id))
                                            }
                                            className="btn  delete-red-text p-0 m-0 "
                                          >
                                            Delete
                                          </button>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-12">
                              <div className="select-table-bottom-r d-flex justify-content-end">
                                <label>
                                  Results per page
                                  <select
                                    onChange={(event) =>
                                      setPage(event.target.value)
                                    }
                                    className="select-page-count-bottom"
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DentistAll;
