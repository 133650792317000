import admin_avatar_header from "../../../assets/images/avatar.svg";
import Layout from "../layout/Layout";
import io from "socket.io-client";
import { useState, useEffect, useRef } from "react";
import ChatBox from "../chat/ChatBox";
import { useDispatch, useSelector } from "react-redux";
import { delChat, getMembersForChat } from "../../../Redux/Actions/chat";
import MomentFunc from "../../../utils/MomentDateTime";
import { pushNeedAssist } from "../../../Redux/Reducers/admin/adminPanelSlice";
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";

export default function LiveChat() {
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const dispatch = useDispatch();
  const socket = useRef();
  const [startChat, setStartChat] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [onlineUsers, setOnlineUsers] = useState();
  const [online, setOnline] = useState();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(5);
  const [pageCount, setPageCount] = useState();
  const [resultOffset, setResultOffset] = useState(0);
  const [searchedMembers, setSearchedMembers] = useState("");
  const [viewVal, setViewVal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const needAssistanceMembers = useSelector(
    (state) => state.adminPanel.needAssistanceMembers
  );

  useEffect(() => {
    socket.current = io.connect(BASE_URL);
    dispatch(getMembersForChat());
    return () => {
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket.current && online) {
      socket.current.on("get-users", (users) => {
        setOnlineUsers(users);
      });
      socket.current.on("get-assist-member", (assistMember) => {
        dispatch(pushNeedAssist(assistMember));
      });
    }
  }, [socket.current, online]);

  const handleShowChat = (val) => {
    setStartChat(val);
    setUserDetails(null);
  };

  const connectSocket = () => {
    setOnline(true);
    if (socket.current && socket.current.connected) {
      socket.current.emit("new-user-add", "doctor");
    } else if (!socket.current || !socket.current.connected) {
      socket.current = io.connect(BASE_URL);
      socket.current.emit("new-user-add", "doctor");
    }
  };

  const disconnectSocket = () => {
    setOnline(false);
    socket.current.disconnect();
  };

  useEffect(() => {
    if (page && searchedMembers) {
      setPageCount(Math.ceil(searchedMembers.length / page));
    }
  }, [searchedMembers, page]);

  useEffect(() => {
    if (needAssistanceMembers) {
      setSearchedMembers(needAssistanceMembers);
    }
  }, [needAssistanceMembers]);

  useEffect(() => {
    const newArr = needAssistanceMembers?.filter((data) =>
      data?.user_id?.firstName.toLowerCase().includes(search)
    );
    setSearchedMembers(newArr);
  }, [search]);

  const handlePageClick = (event) => {
    setResultOffset((event.selected * page) % searchedMembers.length);
  };

  const handleChatDelete = () => {
    dispatch(delChat(deleteId));
    setViewVal(false);
  };

  return (
    <Layout>
      <div id="layout-wrapper" className="bg-dash admin-table">
        <Modal
          show={viewVal}
          onHide={() => setViewVal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="delete_admin_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Chat
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className="text-center mb-0">
              Are you sure you want to delete this Chat?
            </h4>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleChatDelete}
              type="submit"
              className="btn btn-primary btn-custom btn-lg w-100 submit_btn confirmation_btn"
            >
              Yes
            </button>
            <button
              onClick={() => setViewVal(false)}
              type="submit"
              className="btn btn-primary btn-custom btn-lg w-100 submit_btn confirmation_btn"
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div>
                      <span className="small_text">Live Chat</span>
                      <h4 className="mb-sm-0 font-size-28">Live Chat</h4>
                    </div>
                    {/* <div className="center-part-h">
                      <button id="btn">
                        <span
                          onClick={disconnectSocket}
                          id="light"
                          className="dark-light "
                        >
                          Off Line
                        </span>
                        <span
                          onClick={connectSocket}
                          id="dark"
                          className="dark-light"
                        >
                          Available
                        </span>
                      </button>
                    </div> */}

                    <div class="switches-container">
                      <input
                        onClick={disconnectSocket}
                        type="radio"
                        id="switchMonthly"
                        name="switchPlan"
                        value="Monthly"
                      />
                      <input
                        onClick={connectSocket}
                        type="radio"
                        id="switchYearly"
                        name="switchPlan"
                        value="Yearly"
                      />
                      <label for="switchMonthly">Off Line</label>
                      <label for="switchYearly" className="white">
                        Available
                      </label>
                      <div class="switch-wrapper">
                        <div class="switch">
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>

                    <div className="page-title-right">
                      {/* <!-- App Search--> */}
                      <form className="app-search d-none d-lg-block ">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="position-relative w-100">
                            <input
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value), setResultOffset(0);
                              }}
                              type="text"
                              className="form-control"
                              placeholder="Search"
                            />
                            <span className="bx bx-search"></span>
                          </div>
                          {/* <img
                            src={admin_avatar_header}
                            className="avata_serch"
                            alt=""
                          /> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title -->
            <!-- table row start --> */}
              <div className="row">
                <div className="col-xl-8">
                  <div className="members_tbl">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Chats</h4>
                        </div>
                        <div className="table-responsive table-defaut-design  chat_table-height">
                          <table
                            id="datatable"
                            className="table  vehicles_table  w-100 dataTable chat_table"
                            role="grid"
                            aria-describedby="datatable_info"
                          >
                            <thead>
                              <tr role="row">       
                                <th>Chat #</th>          
                                <th>Name</th>
                                <th>Member Number</th>
                                <th>Message Sent</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody className="td_color trans_table_fix_height">
                              {searchedMembers.length > 0 ? (
                                searchedMembers
                                  ?.slice(resultOffset, resultOffset + page)
                                  ?.map((member, index) => {
                                    return (
                                      <tr key={member?._id} valign="top">
                                        <td>{index + 1}</td>
                                        <td>
                                          {member?.user_id?.firstName +
                                            " " +
                                            member?.user_id?.lastName}
                                        </td>
                                        <td>{member?.user_id?.memberNo}</td>
                                        <td>
                                          {MomentFunc.Time(
                                            member?.chatsArray.at(-1)?.time
                                          )}
                                        </td>
                                        <td className="startChat">
                                          <button
                                            onClick={() => {
                                              setUserDetails({
                                                user_id: member?.user_id?._id,
                                                conversationId:
                                                  member?.conversationId,
                                                fullName:
                                                  member?.user_id?.firstName +
                                                  " " +
                                                  member?.user_id?.lastName,
                                              });
                                              setStartChat(true);
                                            }}
                                            className="btn pending_genr "
                                          >
                                            Start Chat
                                          </button>
                                        </td>
                                        <td className="deleteChat">
                                          <button
                                            onClick={
                                              () => {
                                                setDeleteId(member._id);
                                                setViewVal(true);
                                              }
                                              // dispatch(delChat(member._id))
                                            }
                                            className="btn delete-disable deletChat-btn"
                                          >
                                            Delete Chat
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  <td
                                    colSpan={5}
                                    valign={"middle"}
                                    className="h-250"
                                  >
                                    <p className="no_content_table">
                                      No Live Chat
                                    </p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center pagination_inner mt-1">
                      <ReactPaginate
                        className="pagination"
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </div>
                </div>

                {!startChat && !userDetails ? (
                  <div className="col-xl-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="chat-box-c">
                          <div className="chat-area-height d-flex justify-content-center align-items-center chat-height-fix">
                            <div className="placeholder-chat-text">
                              No Live Chats Found
                            </div>
                          </div>
                          <div className="send-msg-part">
                            <textarea
                              name=""
                              id=""
                              placeholder="Type here..."
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  socket.current &&
                  online && (
                    <ChatBox
                      showChat={handleShowChat}
                      socket={socket.current}
                      userDetails={userDetails}
                      onlineUsers={onlineUsers}
                    />
                  )
                )}
              </div>
              {/* <!-- table row end --> */}
            </div>
            {/* <!-- container fluid --> */}
          </div>
          {/* <!-- End Page-content --> */}
        </div>
        {/* <!-- end main content--> */}
      </div>
    </Layout>
  );
}
