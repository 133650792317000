import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  assignIssueStatus,
  getHelpAndSupportIssue,
} from "../../Redux/Actions/admin/adminPanel";
import moment from "moment";
import options from "../../utils/staticData";
import { useNavigate } from "react-router-dom";

export default function ReportIssue() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const helpSupportLists = useSelector(
    (state) => state.adminPanel.helpAndSupportList
  );

  useEffect(() => {
    dispatch(getHelpAndSupportIssue());
  }, [dispatch]);

  const [statuses, setStatuses] = useState({});

  const handleAssignStatus = (e, id) => {
    const selectedStatus = e.target.value;
    setStatuses((prevStatuses) => ({
      ...prevStatuses,
      [id]: selectedStatus,
    }));

    const val = {
      id: id,
      status: selectedStatus == "1" ? 1 : 2,
    };
    dispatch(assignIssueStatus(val));
  };

  return (
    <Layout>
      {" "}
      <div id="layout-wrapper" className="bg-dash admin-table">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div>
                      <h4 className="mb-sm-0 font-size-28">Reported Issues</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12">
                  <div className="members_tbl">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Reported Issues</h4>
                        </div>
                        <div className="table-responsive table-defaut-design dealer-table-c">
                          <table
                            id="datatable"
                            className="table  vehicles_table  w-100"
                          >
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Reported</th>
                                <th>Reported By</th>
                                <th>Issue Type</th>
                                <th>Message</th>
                                <th>Mark As</th>
                              </tr>
                            </thead>
                            <tbody className="td_color trans_table_fix_height">
                              {helpSupportLists?.length > 0
                                ? helpSupportLists?.map((elem, i) => {
                                    const currentStatus =
                                      statuses[elem._id] || elem.status;
                                    return (
                                      <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>
                                          {moment(elem?.createdAt).format(
                                            "DD/MM/YYYY hh:mm"
                                          )}
                                        </td>
                                        <td
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            navigate(
                                              `/admin/memberdentistdetails/${elem?.user_id?._id}`
                                            )
                                          }
                                        >
                                          {elem?.user_id?.firstName
                                            ? elem.user_id.firstName
                                                .charAt(0)
                                                .toUpperCase() +
                                              elem.user_id.firstName.slice(1)
                                            : ""}
                                          {elem?.user_id?.firstName &&
                                          elem?.user_id?.lastName
                                            ? " "
                                            : ""}
                                          {elem?.user_id?.lastName
                                            ? elem.user_id.lastName
                                                .charAt(0)
                                                .toUpperCase() +
                                              elem.user_id.lastName.slice(1)
                                            : ""}
                                        </td>

                                        <td>
                                          {elem?.issue_type
                                            ? options[elem?.issue_type]
                                            : "-"}
                                        </td>
                                        <td>{elem?.desc ? elem?.desc : "-"}</td>
                                        <td>
                                          <div className="drop-select">
                                            <select
                                              value={currentStatus}
                                              onChange={(e) =>
                                                handleAssignStatus(e, elem._id)
                                              }
                                              className={
                                                "form-control td-select-element"
                                              }
                                            >
                                              <option value="">
                                                {" "}
                                                Not Assigned
                                              </option>
                                              <option value={1}>
                                                {" "}
                                                Resoloved
                                              </option>
                                              <option value={2}> Open</option>
                                            </select>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })
                                : "No Data"}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-12">
                            <div className="select-table-bottom-r d-flex justify-content-end">
                              <label>
                                Results per page
                                <select
                                  onChange={(event) =>
                                    setPage(event.target.value)
                                  }
                                  className="select-page-count-bottom"
                                >
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
