 const options = [
  "",
  "I have a problem regarding a ToothAid member",
  "I have a problem with my account",
  "I have a problem with billing",
  "I have a technical issue",
  "I have a suggestion for improvement",
  "Something Else",
];

export default  options;


export  function calculateAge(birthDate) {
  if(birthDate) {
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDifference = today.getMonth() - birth.getMonth();

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
      age--;
  }

  return age;
} else {
  return
}
}

 export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
