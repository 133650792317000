import React from "react";
import ReactPaginate from "react-paginate";

const PaginationComponent = ({ handlePageClick, pageCounttotal }) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="justify-content-center pagination_inner mt-1">
        <ReactPaginate
          className="pagination"
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          pageCount={pageCounttotal}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

export default PaginationComponent;
