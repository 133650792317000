
import React from "react";

const DentistDetail = ({ fullName, email, age, address }) => {

    console.log(fullName, "Sdfasdffadsfasd")

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="tbl_user_info">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative border-b-c">
                <h4 className="title_text">Dentist Details</h4>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="view-label-input">
                          Name
                        </label>
                        <div className="input-view-text">{fullName}</div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="view-label-input">
                          Age
                        </label>
                        <div className="input-view-text">{age}</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="view-label-input">
                          Email
                        </label>
                        <div className="input-view-text">{email}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="view-label-input">
                    Address
                  </label>
                  <div className="form-group mb-3">
                    <div className="input-view-text">

                      {(
                        address?.houseNo +
                        " " +
                        address?.street +
                        " " +
                        address?.secondLine
                      ).replace(/undefined/g, "")}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    {/* <div className="input-view-text">{address?.townCity}</div> */}
                  </div>
                  <div className="form-group mb-3">
                    {/* <div className="input-view-text">{address?.postCode}</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DentistDetail;
